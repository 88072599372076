import type { GetStaticProps, NextPage } from 'next';
import { Trans, useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import dynamic from 'next/dynamic';
import withUserRequired from '@components/hoc/withUserRequired';
import MainLayout from '@components/layouts/MainLayout';
import useUser from '@hooks/useUser';

const WelcomeStepper = dynamic(import('@components/steppers/WelcomeStepper'));
const PaymentMethodSelector = dynamic(import('@components/payment/PaymentMethodSelector'));

/**
 * '/' page.
 */
const HomePage: NextPage = withUserRequired(() => {
  const { user, loading } = useUser();
  const { t } = useTranslation();
  const title = !user?.state.termsAccepted
    ? t('getting-started', { defaultValue: 'Getting started' })
    : t('buy-dps', { defaultValue: 'Buy DPS' });

  return (
    <MainLayout
      title={title}
      loading={loading ? <Trans i18nKey="loading-your-profile">Loading your profile</Trans> : false}
    >
      {!user?.state.termsAccepted ? (
        <>
          <h1 className="my-8 md:my-12 md:text-5xl">
            <Trans i18nKey="welcome:welcome-to-deepsquare">Welcome to DeepSquare</Trans>
          </h1>
          <WelcomeStepper />
        </>
      ) : (
        <>
          <h1 className="my-8 md:my-12 md:text-5xl">
            <Trans i18nKey="invest:invest-in-deepsquare">Invest in DeepSquare</Trans>
          </h1>
          <p className="md:text-lg pb-4 md:pb-8">
            <Trans i18nKey="invest:payment-methods">
              Two investment methods available: USDC and AVAX for cryptocurrency, or wire transfer. Select your payment
              method below.
            </Trans>
          </p>
          <PaymentMethodSelector />
        </>
      )}
    </MainLayout>
  );
});

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: await serverSideTranslations(locale ?? 'en', ['common', 'invest', 'welcome']),
});

export default withUserRequired(HomePage);
